import React from 'react';
import {SectionTilesProps} from '../../utils/SectionProps';
import Image from '../elements/Image';


const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const FeaturesTile = ({
                          url,
                          image,
                          companyName,
                          companyDescription,
                          topOuterDivider,
                          bottomOuterDivider,
                          topDivider,
                          bottomDivider,
                          hasBgColor,
                          invertColor,
                          pushLeft,
                          ...props
                      }) => {
    return (

        <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
            <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                        <p>
                        <Image
                            src={image}
                            alt="Company logo"
                            width={128}
                            height={128}
                            />
                        </p>
                </div>
                <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                        <a href={url}>{companyName}</a>
                    </h4>
                    <p className="m-0 text-sm">
                        {companyDescription}
                    </p>

                </div>
            </div>
        </div>


    )
        ;
}

FeaturesTile.propTypes = propTypes;
FeaturesTile.defaultProps = defaultProps;

export default FeaturesTile;