import React from 'react';
// import sections
import FeaturesTiles from '../components/sections/FeaturesTiles';

const Home = () => {

    return (
        <>
            <FeaturesTiles/>
        </>
    );
}

export default Home;