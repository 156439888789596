import React from 'react';
import classNames from 'classnames';
import {SectionTilesProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import FeaturesTile from './FeaturesTile'

//const defaultImage = './../../assets/images/feature-tile-icon-06.svg';

const companies = [
    {
        "name": "Secundo AS",
        "image": require("./../../assets/images/secundo-logo-new-e1635857548747.png"),
        "url": "https://secundo.no",
        "description": "Secundo er et norsk firma som lager gjenbruksteknologi, og ble startet av Eirik Broms i 2021.\n" +
            "\n" +
            "Secundos visjon er å gjøre det så enkelt å gjenbruke fine ting at det ikke lenger er noe reelt alternativ å kaste de. Gjennom moderne teknologi og en sømløs salgsprosess skal Secundo være enkelt og effektivt. \n"
    },
    {
        "name": "Daal Noise Control Systems AS",
        "image": require("../../assets/images/daal_logo_white-600x199.png"),
        "url": "https://www.daal.no/",
        "description": "DAAL ble etablert i 2016 i Trondheim" +
            " av Dag Loe and Sigmund Birkeland, og deres team har utviklet en\n" +
            "teknologi som spesifikt tar sikte på uønsket og farlig støy som forekommer\n" +
            "på innsiden en motorsykkelhjelm på en kjøretur. Prototypen er testet og verifisert\n" +
            "for hastigheter opp til 140 km/t. "
    },
    {
        "name": "Mano Pizza AS",
        "image": require("../../assets/images/mano_logo.jpg"),
        "url": "https://mano.pizza/",
        "description": "Mano betyr «hånd» og pizza er håndverk. Derfor lager vi den for hånd påtradisjonelt napolitansk vis. Deigen hviler i minst ett døgn og strekkes ut for hånd. Kombinert med vår egen pizzasaus av Toscana-tomater,gir den lette ogluftige deigen det vi synes er den perfekte pizza."
    }, {
        "name": "Wiral Technologies AS",
        "image": require("./../../assets/images/wiral_logo.png"),
        "url": "https://www.wiralcam.com/",
        "description": "We’re a crowdfunded startup company - we started out with a successful\n" +
            "Kickstarter campaign (100% funded in under 4 minutes) and have kept growing ever\n" +
            "since.\n" +
            "Our first product - Wiral LITE, is a versatile cable cam system designed to\n" +
            "create unique, dynamic shots. It’s an awesome accessory for film production,\n" +
            "sports, lifestyle, coaching and other types of shoots, both indoors and\n" +
            "outdoors."
    }, {
        "name": "Kanari AS",
        "image": require("./../../assets/images/kanari_logo.png"),
        "url": "https://kanari.com",
        "description": "Kanari makes sure that complex IT systems work as intended and to best effect. We lead the way on stability and performance analytics (SPA) in the Nordic region."
    }, {
        "name": "Casper Eats AS / Food Family",
        "image": require("./../../assets/images/food_family.png"),
        "url": "https://www.foodfamily.no/",
        "description": "Food Family er en familie av restauranter. Du kan bestille fra alle våre\n" +
            "restauranter på samme ordre. Vi lanserer stadig nye matkonsepter basert på hva\n" +
            "folk ønsker, så følg med."
    }, {
        "name": "Solgt.no AS",
        "url": "https://solgt.no",
        "image": require("./../../assets/images/solgt_logo.svg"),
        "description": "Boligsalg trenger ikke være så slitsomt.\n" +
            "Du får et uforpliktende tilbud basert på vår teknologi og informasjonen du har\n" +
            "gitt oss.\n" +
            "Tjenesten er tilgjengelig for selveier og andelsboliger i Oslo til en verdi\n" +
            "under 10 millioner kroner.\n" +
            "Ingen visning, annonsering eller forberedelser er nødvendig."
    }, {
        "name": "Pinsj AS",
        "url": "https://app.pinsj.no/",
        "image": require("./../../assets/images/pinsj_logo.svg"),
        "description": "Pinsj er en grønn, digital plattform hvor både privatpersoner og butikker kan drive utleie, utlån og bruktsalg av sport- og friluftsutstyr. Lanseringsklar!"
    }, {
        "name": "Frank Walton AS",
        "url": "https://frankwalton.no/",
        "image": require("../../assets/images/frankwaltonlogo.png"),
        "description": "Norsk brillemerke med internasjonale vekstambisjoner! 16 000+ solgte briller og solbriller, positivt driftsresultat i 2020, bevist konsept."
    }, {
        "name": "Wavefoil AS",
        "url": "https://wavefoil.com/",
        "image": require("./../../assets/images/wavefoil_liggende400.png"),
        "description": "Wavefoil reduserer drivstofforbruket og øker komforten til skip i bølger. Teknologien er bevist, vi får solgt produktene våre til målpris og er i kraftig vekst."
    }, {
        "name": "Businessclass.com AS",
        "url": "http://businessclass.com/",
        "image": require("./../../assets/images/businessclass_logo.svg"),
        "description": "High-end satsing innen travel tech. Globale ambisjoner i premium segmentet. Stjernespekket team og investorer i satsing på fremtidsrettet reisesøk."
    }, {
        "name": "Greindx AS",
        "url": "https://www.greindx.com/",
        "image": require("../../assets/images/greindx_logo.png"),
        "description": "Rask og nøyaktig identifisering og analyse av kreftceller fra blodprøve muliggjør tidlig diagnose og valg av persontilpasset behandling innen timer."
    }, {
        "name": "Skogluft AS",
        "image": require("./../../assets/images/Skogluft-logo-web_192x64.png"),
        "url": "https://www.skogluft.com/",
        "description": "Nyskapende og grønne designprodukter for vertikal matdyrking og levende plantevegger innendørs."
    }, {
        "name": "Knitandnote AS",
        "image": require("./../../assets/images/knitandnote_logo.png"),
        "url": "https://knitandnote.com/",
        "description": "I et globalt milliardmarked mangler 80 millioner strikkere gode digitale tjenester. knit&note forandrer dette. Nærmere 20 000 brukere måneder etter lansering."
    }, {
        "name": "Tillit Forsikring AS",
        "url": "https://www.tillit.no/",
        "image": require("./../../assets/images/tillit-logo.svg"),
        "description": "Heldigital forsikring som revolusjonerer bransjen. Automatiserte og skalerbare løsninger, lave priser og enkel skadebehandling. Globale ambisjoner."
    }, {
        "name": "Horde AS",
        "url": "https://horde.no/",
        "image": require("./../../assets/images/horde_logo.png"),
        "description": "Horde er et av Norges mest populære og raskest voksende fintech-selskaper. Siden lansering i august 2019, har appen fått nærmere 170.000 brukere og opprettholder en imponerende vekst. Appen, som allerede har flere brukere enn de fleste norske banker, får mye medieomtale som en seriøs utfordrer innenfor privatøkonomi."
    }, {
        "name": "Glex AS",
        "url": "https://www.glex.no/",
        "image": require("./../../assets/images/GLEX_logo_white_vector.svg"),
        "description": "Glex er et softwareselskap som utvikler avanserte analyse- og samarbeidsverktøy for energi og industriselskaper. Vårt første produkt, Glex Energy, har vært i utvikling siden 2016, og har i dag flere betalende energiselskaper på kundelisten."
    }, {
        "name": "Salmotrace AS",
        "image": require("./../../assets/images/feature-tile-icon-06.svg"),
        "description": "SalmoTrace har utviklet og skal kommersialisere og levere DNA-sporingsteknologi som muliggjør sporing av oppdrettslaks gjennom verdikjeden, fra laksen er smolt og helt frem til slakting, distribusjon og servering av det endelige laksemåltidet."
    }, {
        "name": "Renteradar AS",
        "url": "https://renteradar.no/",
        "image": require("./../../assets/images/renteradar.svg"),
        "description": "Siden lanseringen i 2020 har Renteradar.no fått over 200 000 besøkende og 35 000 registrerte brukere med over 100 milliarder i boliglån. Tjenesten har så langt spart brukerne for over 200 millioner kroner i årlige renteutgifter."
    }, {
        "name": "ParkIT AS",
        "url": "https://www.parkit.as/",
        "image": require("./../../assets/images/parkit-logo.svg"),
        "description": "Parkit er en digital plattform for deling av parkeringsplasser og elbilladere som er bra for klimaet, og lommeboken. Med Parkit-appen kan bilførere enkelt finne, booke og betale for parkeringsplasser på en og samme plattform."
    }, {
        "name": "Villbrygg AS",
        "url": "http://villbrygg.no/",
        "image": require("./../../assets/images/villbrygg_logo.png"),
        "description": "Villbrygg lager kompromissløs alkoholfri drikke ved å fermentere blant annet urter og nordiske ville vekster. Skapt fra et behov for spennende alkoholfri drikke som kan nytes slik vi vanligvis nyter alkohol. Drikkene våre har komplekse smaker og finnes på alt fra Michelin restauranter til nabolags-bistroer, cocktailbarer og gourmet-butikker over hele Norge."
    }

]


const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
                           className,
                           topOuterDivider,
                           bottomOuterDivider,
                           topDivider,
                           bottomDivider,
                           hasBgColor,
                           invertColor,
                           pushLeft,
                           ...props
                       }) => {

    const outerClasses = classNames(
        'features-tiles section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'features-tiles-inner section-inner pt-0',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );
    const tilesClasses = classNames(
        'tiles-wrap center-content',
        pushLeft && 'push-left'
    );


    const sectionHeader = {
        title: 'Tutnes Holding AS',
        paragraph: 'Formålet til selskapet er å drive med utleie av egen eller leid fast eiendom ellers. Samt investere i andre selskaper.'
    };

    return (
        <section
            {...props}
            className={outerClasses}
        >
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content"/>
                    <div className={tilesClasses}>
                        {companies.length > 0 && companies.map((item) => <FeaturesTile companyName={item.name}
                                                                                       companyDescription={item.description}
                                                                                       url={item.url} image={item.image}/>)}
                    </div>
                </div>
            </div>
        </section>
    );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;